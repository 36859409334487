import { DOCUMENT, Location } from "@angular/common";
import {Component, Inject, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Hero} from "src/app/interfaces/hero";
import {PageData} from "src/app/interfaces/page-data";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  title!: string;
  hero!: Hero;
  showFontShareSection = false;
  
  constructor(private route: ActivatedRoute, @Inject(DOCUMENT) private document:Document) {}
  ngOnInit() {
    const pageName = this.route.snapshot.data["pageName"];
    if (pageName === "article" || pageName === "book" || pageName === "infograph") this.showFontShareSection = true;
    const {title, hero} = this.route.snapshot.data["page"] as PageData;
    this.title = title;
    this.hero = hero as Hero;
  }

  
  public get slashesCount() : number {    
    return this.document.location.pathname.split('/').length
  }
  
}

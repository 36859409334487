import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {ErrorMessages} from "src/app/utils/constants";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {environment} from "src/environments/environment";
import {ApiService} from "src/app/services/api.service";
import {ActivatedRoute} from "@angular/router";
import {Country, RegisterationPageData} from "src/app/interfaces/registeration-page";
import {TarbyaCategory} from "src/app/interfaces/tarbya-categories";

@Component({
  selector: "app-registeration-page",
  templateUrl: "./registeration-page.component.html",
  styleUrls: ["./registeration-page.component.scss"],
})
export class RegisterationPageComponent implements OnInit {
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {}

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;
  errorMessage!: string;
  unsubscribe$ = new ReplaySubject(1);
  formGroup = this.fb.group(
    {
      firstName: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      middleName: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      lastName: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      email: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),

      password: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)],
      }),

      confirmPassword: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),

      country: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),

      employer: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),

      gender: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),

      selectedCategories: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),

      phone: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),

      mailingList: new FormControl(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validator: this.passwordMatchValidator,
    },
  );

  get firstName() {
    return this.formGroup.controls["firstName"];
  }
  get middleName() {
    return this.formGroup.controls["middleName"];
  }
  get lastName() {
    return this.formGroup.controls["lastName"];
  }
  get email() {
    return this.formGroup.controls["email"];
  }
  get password() {
    return this.formGroup.controls["password"];
  }
  get confirmPassword() {
    return this.formGroup.controls["confirmPassword"];
  }
  get country() {
    return this.formGroup.controls["country"];
  }
  get employer() {
    return this.formGroup.controls["employer"];
  }
  get gender() {
    return this.formGroup.controls["gender"];
  }
  get selectedCategories() {
    return this.formGroup.controls["selectedCategories"];
  }
  get phone() {
    return this.formGroup.controls["phone"];
  }
  get mailingList() {
    return this.formGroup.controls["mailingList"];
  }

  ErrorMessages = ErrorMessages;
  countries!: Country[];
  allCategories!: TarbyaCategory[];
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as RegisterationPageData;
    this.countries = page.data.countries;
    this.allCategories = page.data.allCategories;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  passwordMatchValidator(form: FormGroup) {
    const password = form.get("password");
    const confirmPassword = form.get("confirmPassword");
    if (password && confirmPassword) {
      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({confirm: true});
      } else {
        confirmPassword.setErrors(null);
      }
    }
  }

  onSubmit() {
    if (this.formGroup.invalid) return;
    let registerUserObj = this.formGroup.value;
    this.registerUser(registerUserObj);
  }

  registerUser(registerUserObj: User) {
    this.sending = true;
    this.recaptchaV3Service
      .execute(environment.recaptcha.registerUserAction)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token) => {
          return this.apiService.submitAction(environment.recaptcha.registerUserAction, token, registerUserObj);
        }),
      )
      .subscribe({
        next: (data) => {
          this.sending = false;
          this.success = true;
          this.formGroup.reset();
        },
        error: ({error}) => {
          this.sending = this.success = false;
          this.hasError = true;
          if (error.message === "Email already exists") {
            this.errorMessage = "لقد تم التسجيل مسبقا باستخدام هذا الايميل.";
          }
        },
      });
  }
}

export interface User {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  password: string;
  country: string;
  employer: string;
  gender: string;
  selectedCategories: string;
  phone: string;
  mailingList: boolean;
}

<app-page>
  <page-header></page-header>
  <page-content>
    <div *ngIf="educationalHighlight" id="read-more" class="py-5">
      <div class="container">
        <div class="text-center">
          <img
            [src]="educationalHighlight.image | imageLink"
            class="img-fluid rounded-3 shadow-lg w-75"
            alt=""
            style="max-height: 80vh !important"
          />
        </div>

        <h1 class="fs-4 mt-5 mb-1 fw-bold">عنوان الإصدار:</h1>
        <p>
          {{ educationalHighlight.title }}
        </p>
        <h1 class="fs-4 mt-3 mb-1 fw-bold">المؤلف:</h1>
        <span>{{ educationalHighlight.author }}</span>
        <h1 class="fs-4 mt-3 mb-1 fw-bold">سنة النشر:</h1>
        <p>{{ educationalHighlight.date }}</p>
        <h1 class="fs-4 mt-3 mb-1 fw-bold">رابط الإصدار على المتجر:</h1>
        <a [href]="educationalHighlight.storeUrl" target="_blank">{{ educationalHighlight.storeUrl }}</a>

        <h1 class="fs-4 mt-3 mb-1 fw-bold">المحتوي:</h1>

        <p [innerHTML]="educationalHighlight.summary" class="mb-2"></p>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

<div id="home-slider">
  <div flickity [flickityConfig]="flickityConfig" class="slider">
    <div
      *ngFor="let banner of banners"
      class="slider-slide"
      style="
        background-image: url('{{ banner.image | imageLink }}');
      "
    >
      <div class="container">
        <div class="captions"></div>
      </div>
    </div>
  </div>
</div>

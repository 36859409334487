import {Component, ElementRef, HostListener, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Modal} from "bootstrap";
import {ReplaySubject, takeUntil} from "rxjs";
import {ModalService} from "src/app/services/modal.service";

@Component({
  selector: "app-search-popup",
  templateUrl: "./search-popup.component.html",
  styleUrls: ["./search-popup.component.scss"],
})
export class SearchPopupComponent implements OnInit {
  isModalOpen: boolean = false;
  popUpModal: any;
  searchKey = "";
  @ViewChild("myInput") myInput!: ElementRef;

  unsubscrib$ = new ReplaySubject(1);

  constructor(private modalService: ModalService, private router: Router) {}

  ngOnInit() {
    this.modalService
      .isModalOpen()
      .pipe(takeUntil(this.unsubscrib$))
      .subscribe({
        next: (state) => {
          this.isModalOpen = state;
          this.popUpModal = new Modal("#popupModal");
          if (this.isModalOpen) {
            this.popUpModal.show();
          } else {
            this.popUpModal.hide();
          }
        },
      });
  }
  ESCAPE_KEYCODE = 27;

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      this.closeModal();
    }
  }

  closeModal() {
    this.isModalOpen = false;
    this.popUpModal.hide();
    this.modalService.setModalState(false);
  }

  ngOnDestroy(): void {
    this.closeModal();
    this.unsubscrib$.next(1);
    this.unsubscrib$.complete();
  }
  setFocus() {
    this.myInput.nativeElement.focus();
  }
  search() {
    this.closeModal();
    const queryParams = {q: this.searchKey};
    this.router.navigate(["/search"], {queryParams: queryParams});
  }
}

import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {EducationalHighlightsDetailsPageData} from "src/app/interfaces/educational-highlights-details-page";
import {EducationalHighlight} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-educational-highlights-details-page",
  templateUrl: "./educational-highlights-details-page.component.html",
  styleUrls: ["./educational-highlights-details-page.component.scss"],
})
export class EducationalHighlightsDetailsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  educationalHighlight!: EducationalHighlight;
  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as EducationalHighlightsDetailsPageData;
    this.educationalHighlight = page.data.educationalHighlight;
  }
}

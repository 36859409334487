import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, map} from "rxjs";
import {Apollo} from "apollo-angular";
import {GET_ARTICLES} from "../queries/articles.page.query";
import {ArticlesPageData} from "../interfaces/articles.pages";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ArticlesPagesResolver implements Resolve<ArticlesPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticlesPageData> {
    return this.apollo
      .query({
        query: GET_ARTICLES,
        variables: {pageType: route.data["pageType"]},
      })
      .pipe(
        map((result): ArticlesPageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: meta[0].nameAr,
            hero: {
              title: meta[0].nameAr,
              image: meta[0].image,
            },
            data: page,
            meta: meta[0],
          };
        }),
      );
  }
}

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import gql from "graphql-tag";
import {ReplaySubject, map, takeUntil} from "rxjs";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
  searchKey = "";
  currentPage = -1;
  totalPages!: number;
  searchResults: any[] = [];
  assetsUrl = environment.assetsURI;
  unsubscribe$ = new ReplaySubject(1);

  ITEMS_PER_PAGE = 12;
  cursors: {[key: string]: {limit: number; offset: number}}[] = [];

  constructor(private apollo: Apollo, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams["q"]) {
      this.searchKey = decodeURIComponent(this.route.snapshot.queryParams["q"]);
    }
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
  onSubmit() {
    this.updateQueryString();
    this.getLimitsAndOffsets()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cursors) => {
        this.cursors = cursors;
        this.currentPage = 0;
        this.search();
      });
  }

  search(): void {
    const cursor = this.cursors[this.currentPage];
    if (!cursor) {
      this.searchResults = [];
      return;
    }

    const searchQuery = gql`
      query search(
        $searchKey: String!
        $articlesLimit: Int!
        $articlesOffset: Int!
        $booksLimit: Int!
        $booksOffset: Int!
        $issuesLimit: Int!
        $issuesOffset: Int!
        $infographsLimit: Int!
        $infographsOffset: Int!
      ) {
        articles(
          limit: $articlesLimit
          offset: $articlesOffset
          search: $searchKey
          filter: {status: {_eq: "published"}}
          sort: ["-issue_id.date"]
        ) {
          id
          title
          summary
          author
          content
          images {
            imageId: directus_files_id {
              id
            }
          }
          videos {
            id
            title
            description
          }
          category_id {
            id
            nameAr: name_ar
            nameEn: name_en
            order
            image: header_image {
              id
              filename: filename_download
              title
            }
          }
        }
        books(
          search: $searchKey
          limit: $booksLimit
          offset: $booksOffset
          filter: {status: {_eq: "published"}}
          sort: ["-date_created"]
        ) {
          id
          title
          author
          description
          image {
            id
            filename: filename_download
            title
          }
        }
        infographs(
          search: $searchKey
          limit: $infographsLimit
          offset: $infographsOffset
          filter: {status: {_eq: "published"}}
          sort: ["-date_created"]
        ) {
          id
          title
          bookTitle: book_title
          description
          author
          image {
            id
            filename: filename_download
            title
          }
        }
        issues(
          search: $searchKey
          limit: $issuesLimit
          offset: $issuesOffset
          filter: {status: {_eq: "published"}}
          sort: ["-date_created"]
        ) {
          id
          name
          date
          image {
            id
            title
            filename: filename_download
          }
          url
        }
      }
    `;

    this.apollo
      .query({
        query: searchQuery,
        variables: {
          searchKey: `%${this.searchKey}%`,
          articlesLimit: cursor["articles"].limit,
          booksLimit: cursor["books"].limit,
          issuesLimit: cursor["issues"].limit,
          infographsLimit: cursor["infographs"].limit,
          articlesOffset: cursor["articles"].offset,
          booksOffset: cursor["books"].offset,
          issuesOffset: cursor["issues"].offset,
          infographsOffset: cursor["infographs"].offset,
        },
        fetchPolicy: "no-cache",
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.searchResults = [
          ...result.data.articles,
          ...result.data.books,
          ...result.data.infographs,
          ...result.data.issues,
        ];
      });
  }

  updateQueryString(): void {
    const queryParams = {q: encodeURIComponent(this.searchKey)};
    this.router.navigate([], {queryParams: queryParams});
  }
  onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.search();
  }

  getTotals() {
    const query = gql`
      query search($searchKey: String!) {
        booksPaging: books_aggregated(search: $searchKey) {
          count {
            id
          }
        }
        articlesPaging: articles_aggregated(search: $searchKey) {
          count {
            id
          }
        }

        infographsPaging: infographs_aggregated(search: $searchKey) {
          count {
            id
          }
        }
        issuesPaging: issues_aggregated(search: $searchKey) {
          count {
            id
          }
        }
      }
    `;

    return this.apollo
      .query({
        query,
        variables: {
          searchKey: `%${this.searchKey}%`,
        },
      })
      .pipe(
        map((result: any): {[key in "articles" | "books" | "issues" | "infographs"]: number} => {
          return {
            articles: result.data.articlesPaging[0].count.id,
            books: result.data.booksPaging[0].count.id,
            issues: result.data.issuesPaging[0].count.id,
            infographs: result.data.infographsPaging[0].count.id,
          };
        }),
      );
  }

  makeCursors(limits: Record<string, number>[]) {
    let result: {[key: string]: {limit: number; offset: number}}[] = [];

    let offsets = {
      articles: 0,
      books: 0,
      issues: 0,
      infographs: 0,
    };

    limits.forEach((pageLimits) => {
      let cursor: {[key: string]: {limit: number; offset: number}} = {};
      ["articles", "books", "issues", "infographs"].forEach((key) => {
        const limit = pageLimits[key] || 0;
        cursor[key] = {
          limit,
          offset: offsets[key],
        };
        offsets[key] += limit;
      });
      result.push(cursor);
    });

    return result;
  }

  getLimitsAndOffsets() {
    let pagesLimits: Record<string, number>[] = [];
    return this.getTotals().pipe(
      map((totals) => {
        let itemsCount = Object.keys(totals).reduce((total, key) => {
          total += totals[key];
          return total;
        }, 0);
        this.totalPages = Math.ceil(itemsCount / this.ITEMS_PER_PAGE);
        while (itemsCount > 0) {
          let pageItemsCount = 0,
            pageLimits: Record<string, number> = {};
          for (const key in totals) {
            const take = Math.min(this.ITEMS_PER_PAGE - pageItemsCount, totals[key]);
            if (!take) continue;

            pageItemsCount += take;
            totals[key] -= take;
            pageLimits[key] = take;
          }

          itemsCount -= pageItemsCount;
          pagesLimits.push(pageLimits);
        }

        return this.makeCursors(pagesLimits);
      }),
    );
  }
}

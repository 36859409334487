<app-page>
  <page-header></page-header>
  <page-content>
    <div id="frontpage" class="mt-0">
      <div class="container">
        <div class="py-5">
          <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">الأحدث</h4>

          <div class="row">
            <div *ngFor="let article of latestArticles" class="col-lg-4 col-md-6 mb-3">
              <a routerLink="/{{ pageName }}/{{ article.id }}" target="_self" class="text-dark">
                <div class="frontpage-card">
                  <img
                    *ngIf="article.images[0]"
                    src="{{ assetsURI }}/{{ article.images[0].imageId.id }}"
                    class="mx-auto d-block frontpage-card-image mb-3 hover-shadow"
                    alt=""
                  />

                  <h5 class="fw-bold p-make-2--fixed-lines-ended-with-dots">{{ article.title }}</h5>
                  <!-- <p [innerHTML]="article.summary" class="text-muted my-3 p-make-2--fixed-lines-ended-with-dots"></p> -->

                  <a class="bg-dark fw-bold text-dark bg-opacity-10 p-3 d-block">
                    <i class="fa-solid fa-align-justify me-2"></i>
                    اقرأ
                  </a>
                </div>
              </a>
            </div>
          </div>

          <div *ngIf="totalArticlesCount && latestArticles.length < totalArticlesCount" class="text-end cursor-pointer">
            <a (click)="getNextPage()" target="_self" class="text-dark fs-4">
              المزيد من الموضوعات
              <i class="fa-solid fa-plus text-gold ms-2"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="py-5" style="background-color: #dfe4e7">
        <div class="container">
          <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">الأكثر قراءة</h4>

          <div class="row">
            <div *ngFor="let article of mostViewedArticles" class="col-lg-4 col-md-6 mb-3">
              <a routerLink="/{{ pageName }}/{{ article.id }}" target="_self" class="text-dark">
                <div class="frontpage-card">
                  <img
                    *ngIf="article.images[0]"
                    src="{{ assetsURI }}/{{ article.images[0].imageId.id }}"
                    class="mx-auto d-block frontpage-card-image mb-3 hover-shadow"
                    alt=""
                  />

                  <h5 class="fw-bold p-make-2--fixed-lines-ended-with-dots">{{ article.title }}</h5>
                  <!-- <p [innerHTML]="article.summary" class="text-muted my-3 p-make-2--fixed-lines-ended-with-dots"></p> -->

                  <a class="bg-dark fw-bold text-dark bg-opacity-10 p-3 d-block">
                    <i class="fa-solid fa-align-justify me-2"></i>
                    اقرأ
                  </a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

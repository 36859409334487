<footer class="footer text-center bg-dark text-white py-4">
  <div class="container">
    <p class="mb-0">
      جميع الحقوق محفوظة لمكتب التربية العربي لدول الخليج وتخضع لشروط واتفاق الإستخدام &copy; {{ currentYear }}.
    </p>
  </div>
</footer>

<a
  id="back-to-top"
  href="#"
  target="_self"
  class="btn btn-primary shadow"
  data-bs-toggle="tooltip"
  data-bs-placement="top"
  data-bs-title="العودة لبداية الصفحة"
>
  <i class="fa-solid fa-chevron-up"></i>
</a>

<page-header></page-header>
<div class="container">
  <div class="row">
    <div class="col">
      <h3 class="mb-4 mt-5">البحث في الموقع</h3>
      <form (submit)="onSubmit()">
        <div class="input-group">
          <input
            [(ngModel)]="searchKey"
            name="searchKey"
            type="text"
            class="form-control"
            placeholder="بحث..."
            aria-label="Search"
          />
          <button type="submit" class="btn btn-primary">بحث</button>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <ul class="list-group mt-4">
        <li class="list-group-item pt-3" *ngFor="let result of searchResults; let i = index">
          <div class="d-flex flex-column flex-sm-row">
            <div class="text-center">
              <img
                *ngIf="result.image"
                [src]="result.image | imageLink"
                alt="{{ result.title }}"
                class="img-fluid rounded"
                style="max-width: 300px"
              />
              <img
                *ngIf="result.images"
                src="{{ assetsUrl }}/{{ result.images[0].imageId.id }}"
                alt="{{ result.title }}"
                class="img-fluid rounded"
                style="max-width: 300px"
              />
            </div>
            <div class="ms-3 flex-grow-1 mt-4 mt-md-0">
              <h3 class="h5">{{ result.title || result.name }}</h3>
              <p
                *ngIf="result.summary || result.description"
                class="mb-2"
                [innerHTML]="result.summary || result.description"
              ></p>
              <p *ngIf="result.author" class="mb-0">المؤلف: {{ result.author }}</p>
              <div class="d-flex justify-content-end">
                <a
                  *ngIf="result.__typename === 'articles' && result.category_id"
                  routerLink="/{{ result.category_id.nameEn }}/{{ result.id }}"
                  class="btn btn-primary mt-2 float-left"
                >
                  اقرأ
                </a>
                <a
                  *ngIf="result.__typename === 'books'"
                  routerLink="/books/{{ result.id }}"
                  class="btn btn-primary mt-2 float-left"
                >
                  اقرأ
                </a>
                <a
                  *ngIf="result.__typename === 'infographs'"
                  routerLink="/infographs//{{ result.id }}"
                  class="btn btn-primary mt-2 float-left"
                >
                  اقرأ
                </a>
                <a
                  *ngIf="result.__typename === 'issues'"
                  [href]="result.url"
                  target="_blank"
                  class="btn btn-primary mt-2 float-left"
                >
                  اقرأ
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <nav aria-label="Page navigation" class="mt-4">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{disabled: currentPage === 0}">
            <a class="page-link cursor-pointer" (click)="onPageChange(currentPage - 1)">السابق</a>
          </li>
          <li class="page-item">
            <a class="page-link active">{{ currentPage + 1 }}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled: currentPage + 1 >= totalPages}">
            <a class="page-link cursor-pointer" (click)="onPageChange(currentPage + 1)">التالى</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<page-footer></page-footer>

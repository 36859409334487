import {Component, ElementRef, HostListener, OnInit, ViewChild} from "@angular/core";
import {navbarLinks} from "../navbar/navbar.component";
import {WindowService} from "src/app/services/window.service";
import {faFacebookF, faInstagram, faLinkedin, faLinkedinIn, faSnapchat, faWhatsapp, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private windowService: WindowService) {}

  ngOnInit(): void {}
  faFacebookF = faFacebookF;
  faWhatsapp = faWhatsapp;
  faXTwitter = faXTwitter;
  faSnapchat = faSnapchat;
  faYoutube= faYoutube;
  faInstagram= faInstagram;
  faLinkedin=faLinkedin

  navbar = navbarLinks;

  SCROLL_OFFSET = 300;
  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;
  socialMediaLinks = [
    {
      title: "linkedin",
      icon: faLinkedin,
      href: "https://www.linkedin.com/company/abegs",
    },
    {
      title: "youtube",
      icon: faYoutube,
      href: "https://www.youtube.com/@AbegsOrg",
    },
    {
      title: "instagram",
      icon:faInstagram ,
      href: "https://www.instagram.com/_abegsorg/",
    },
    {
      title: "facebook",
      icon: faFacebookF,
      href: "https://www.facebook.com/abegsorg/",
    },
    {
      title: "x",
      icon: faXTwitter,
      href: "https://x.com/abegsorg",
    },
  ];

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if ((this.windowService.scrollY || 0) > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }
}

<div class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h4 class="fs-3 my-4 fw-bold">الافتتاحية</h4>

        <div class="row">
          <div *ngFor="let openingItem of opening" class="col-md-6 mb-3">
            <a routerLink="/opening/{{ openingItem.id }}" target="_self" class="text-dark">
              <app-front-page-card [article]="openingItem"></app-front-page-card>
            </a>
          </div>
        </div>

        <div class="text-end mb-3 mt-3">
          <a routerLink="/opening" target="_self" class="more-btn text-dark fs-5">
            المزيد من الموضوعات
            <i class="fa-solid fa-plus text-gold ms-2"></i>
          </a>
        </div>
      </div>
      <div *ngIf="issues" class="col d-flex flex-column">
        <h4 class="fs-3 my-4 fw-bold d-flex">
          اصدارات نشرة التربية
          <a routerLink="/issues" target="_self" class="d-inline-block ms-auto text-gold">
            <i class="fa-solid fa-plus"></i>
          </a>
        </h4>

        <img
          [src]="issues[0].image | imageLink"
          class="flex-grow-1 mx-auto d-block w-100 cover"
          [alt]="issues[0].image.title"
        />
        <a
          [href]="issues[0].url"
          target="_blank"
          class="fs-5 fw-bold d-block text-center text-dark bg-black bg-opacity-10 py-2"
        >
          تصفح النسخة الرقمية
        </a>
        <div class="mt-3"></div>
      </div>
    </div>
  </div>
</div>

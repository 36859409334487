import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {Observable, map} from "rxjs";
import {ContactUsPage} from "../interfaces/contact-us-page";

import {GET_CONTACT_US_PAGE} from "../queries/contact-us-page.query";

import {environment} from "src/environments/environment";
import {Article, EducationalHighlight, Video} from "../interfaces/landing-page";
import {GET_LANDING_VIDEOS} from "../queries/landing-page.query";
import {
  GET_ARTICLES_BY_PAGE,
  UPDATE_ARTICLES_VIEWS_COUNT_BY_ID,
  UPDATE_VIDEOS_VIEWS_COUNT_BY_ID,
} from "../queries/articles.page.query";
import {Issue} from "../interfaces/issues-page";
import {GET_ISSUES_BY_PAGE} from "../queries/issues-page.query";
import {TarbyaCategory} from "../interfaces/tarbya-categories";
import {GET_TARBYA_CATEGORIES} from "../queries/tarbya-categories-query";

@Injectable({
  providedIn: "root",
})
export class CMSService {
  constructor(private apollo: Apollo) {}

  getTarbyaCategories(): Observable<{
    tarbyaCategories: TarbyaCategory[];
  }> {
    return this.apollo
      .query({
        query: GET_TARBYA_CATEGORIES,
      })
      .pipe(
        map((result): {tarbyaCategories: TarbyaCategory[]} => {
          const tarbyaCategories = result.data;
          return tarbyaCategories;
        }),
      );
  }

  getContactUsDetails(): Observable<ContactUsPage> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE,
      })
      .pipe(
        map((result): ContactUsPage => {
          const contactus = result.data.page;
          return contactus;
        }),
      );
  }

  getIssuesByPage(page: number): Observable<Issue[]> {
    return this.apollo
      .query({
        query: GET_ISSUES_BY_PAGE,
        variables: {
          limit: environment.limit.issuesLimit,
          page: page,
        },
      })
      .pipe(
        map((result): Issue[] => {
          const issues = result.data.issues;
          return issues;
        }),
      );
  }

  getArticlesByPage(page: number, pageType: number): Observable<Article[]> {
    return this.apollo
      .query({
        query: GET_ARTICLES_BY_PAGE,
        variables: {
          limit: environment.limit.articlesLimit,
          page: page,
          pageType,
        },
      })
      .pipe(
        map((result): Article[] => {
          const articles = result.data.articles;
          return articles;
        }),
      );
  }

  getLandingVideos(): Observable<{
    latestVideos: Video[];
    mostViewedVideos: Video[];
  }> {
    return this.apollo
      .query({
        query: GET_LANDING_VIDEOS,
      })
      .pipe(
        map((result): {latestVideos: Video[]; mostViewedVideos: Video[]} => {
          const videos = result.data;
          return videos;
        }),
      );
  }

  updateArticleViewsCount(articleId: number | string, views: number) {
    return this.apollo.mutate({
      mutation: UPDATE_ARTICLES_VIEWS_COUNT_BY_ID,
      variables: {
        articleId,
        views,
      },
    });
  }

  updateVideoViewsCount(videoId: number | string, views: number) {
    return this.apollo.mutate({
      mutation: UPDATE_VIDEOS_VIEWS_COUNT_BY_ID,
      variables: {
        videoId,
        views,
      },
    });
  }
}

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Issue, IssuesPage, IssuesPageData} from "src/app/interfaces/issues-page";
import {CMSService} from "src/app/services/cms.service";

@Component({
  selector: "app-issues-page",
  templateUrl: "./issues-page.component.html",
  styleUrls: ["./issues-page.component.scss"],
})
export class IssuesPageComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}
  issues: Issue[] = [];
  totalIssuesCount!: number;
  page: number = 1;
  ngOnInit() {
    const page = this.route.snapshot.data["page"] as IssuesPageData;
    this.issues.push(...page.data.issues);

    this.totalIssuesCount = page.data.paging[0].count.id;
  }
  unsubscribe$ = new ReplaySubject(1);
  loading!: boolean;

  getNextPage() {
    this.page++;
    this.loading = true;
    this.cmsService
      .getIssuesByPage(this.page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.issues.push(...result);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}

import {ImageFile} from "./image-file";
import {PageData} from "./page-data";

export interface Issue {
  id: string;
  name: string;
  date: string;
  image: ImageFile;
  url: string;
}

export interface IssuesPage {
  title: string;
  image: ImageFile;
}

export type IssuesPageData = PageData<
  {
    issues: Issue[];
    paging: {count: {id: number}};
  },
  IssuesPage
>;

export enum Country {
  BAHRAIN = "BH",
  YEMEN = "YE",
  KUWAIT = "KW",
  SAUDI_ARABIA = "SA",
  OMAN = "OM",
  QATAR = "QA",
  UNITED_ARAB_EMIRATES = "AE",
  JORDAN = "JO",
  SYRIA = "SY",
  EGYPT = "EG",
  SUDAN = "SD",
  IRAQ = "IQ",
  LIBYA = "LY",
  MOROCCO = "MA",
  TUNISIA = "TN",
  ALGERIA = "DZ",
  LEBANON = "LB",
  MAURITANIA = "MR",
  DJIBOUTI = "DJ",
  SOMALIA = "SO",
  COMOROS = "KM",
  PALESTINE = "PS",
  AUSTRALIA = "AU",
  WALES = "GB-WLS",
  SCOTLAND = "GB-SCT",
  UNITED_KINGDOM = "GB",
  FRANCE = "FR",
  SWEDEN = "SE",
  NETHERLANDS = "NL",
  ESTONIA = "EE",
  JAPAN = "JP",
}

export const countriesI18n: {[key in Country]: string} = {
  [Country.BAHRAIN]: "البحرين",
  [Country.YEMEN]: "اليمن",
  [Country.KUWAIT]: "الكويت",
  [Country.SAUDI_ARABIA]: "السعودية",
  [Country.OMAN]: "عمان",
  [Country.QATAR]: "قطر",
  [Country.UNITED_ARAB_EMIRATES]: "الإمارات",
  [Country.JORDAN]: "اليمن",
  [Country.SYRIA]: "سوريا",
  [Country.EGYPT]: "مصر",
  [Country.SUDAN]: "السودان",
  [Country.IRAQ]: "العراق",
  [Country.LIBYA]: "ليبيا",
  [Country.MOROCCO]: "المغرب",
  [Country.TUNISIA]: "تونس",
  [Country.ALGERIA]: "المغرب",
  [Country.LEBANON]: "لبنان",
  [Country.MAURITANIA]: "موريتانيا",
  [Country.DJIBOUTI]: "جيبوتي",
  [Country.SOMALIA]: "الصومال",
  [Country.COMOROS]: "جزر القمر",
  [Country.PALESTINE]: "فلسطين",
  [Country.AUSTRALIA]: "أستراليا",
  [Country.WALES]: "ويلز",
  [Country.SCOTLAND]: "اسكتلندا",
  [Country.UNITED_KINGDOM]: "المملكة المتحدة",
  [Country.FRANCE]: "فرنسا",
  [Country.SWEDEN]: "السويد",
  [Country.NETHERLANDS]: "هولندا",
  [Country.ESTONIA]: "إستونيا",
  [Country.JAPAN]: "اليابان",
};

export const ErrorMessages = {
  firstNameErrors: [
    {
      type: "required",
      message: "برجاء إدخال الاسم الأول",
    },
  ],

  middleNameErrors: [
    {
      type: "required",
      message: "برجاء إدخال الاسم الأوسط",
    },
  ],

  lastNameErrors: [
    {
      type: "required",
      message: "برجاء إدخال الاسم الأخير",
    },
  ],

  emailErrors: [
    {type: "pattern", message: "برجاء أدخل إيميل صحيح"},
    {type: "required", message: "برجاء إدخال البريد"},
  ],
  passwordErrors: [
    {
      type: "pattern",
      message: "ثمانية أحرف على الأقل, حرف كبير وصغير على الأقل ورقم واحد على الأقل",
    },
    {type: "required", message: "برجاء إدخال كلمة المرور"},
    {type: "confirm", message: "كلمة المرور غير مطابقة"},
  ],

  countryErrors: [
    {
      type: "required",
      message: "برجاء إدخال اسم الدولة",
    },
  ],

  employerErrors: [
    {
      type: "required",
      message: "برجاء إدخال اسم الجهة",
    },
  ],

  genderErrors: [
    {
      type: "required",
      message: "برجاء اختيار النوع",
    },
  ],

  selectedCategoriesErrors: [
    {
      type: "required",
      message: "برجاء اختيار المجالات",
    },
  ],
  phoneErrors: [
    {
      type: "required",
      message: "برجاء ادخال رقم الجوال",
    },
  ],
  mailingListErrors: [
    {
      type: "required",
      message: "برجاء الموافقة علي ارسال المواد الاعلانية عبر البريد الالكترونى",
    },
  ],
};

import {gql} from "apollo-angular";
import {Article, Banner, Video} from "../interfaces/landing-page";
import {Issue} from "../interfaces/issues-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    banners: Banner[];
    opening: Article[];
    latestArticles: Article[];
    mostViewedArticles: Article[];
    issues: Issue[];
    teacingMethods: Article[];
    eLearningTechnology: Article[];
    childhoodStudies: Article[];
    developmentAndTeaching: Article[];
    videos: Video[];
  },
  void
>`
  query {
    banners(filter: {status: {_eq: "published"}}, sort: ["order"]) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
      url
      order
    }
    issues(filter: {status: {_eq: "published"}}, sort: ["-date_created"], limit: 1) {
      id
      name
      date
      image {
        id
        filename: filename_download
        title
      }
      url
    }

    opening: articles(filter: {status: {_eq: "published"}, category_id: {id: {_eq: 1}}}, sort: ["-issue_id.date"], limit: 2) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
    }
    latestArticles: articles(
      filter: {status: {_eq: "published"}}
      sort: ["-issue_id.date"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
    }
    mostViewedArticles: articles(
      filter: {status: {_eq: "published"}}
      sort: ["-views"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
    }
    eLearningTechnology: articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: 2}}}
      sort: ["-issue_id.date"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
    }

    teacingMethods: articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: 4}}}
      sort: ["-issue_id.date"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
    }

    childhoodStudies: articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: 6}}}
      sort: ["-issue_id.date"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
    }

    developmentAndTeaching: articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: 7}}}
      sort: ["-issue_id.date"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
    }

    videos(filter: {status: {_eq: "published"}}, sort: ["-date_created"], limit: 3) {
      id
      title
      videoUrl: video_url
      views
      description
      article: article_id {
        id
        title
      }
    }
  }
`;

export const GET_LANDING_VIDEOS = gql<
  {
    latestVideos: Video[];
    mostViewedVideos: Video[];
  },
  void
>`
  query {
    latestVideos: videos(filter: {status: {_eq: "published"}}, sort: ["-date_created"], limit: 3) {
      id
      title
      videoUrl: video_url
      views
      description
      article: article_id {
        id
        title
      }
    }

    mostViewedVideos: videos(filter: {status: {_eq: "published"}}, sort: ["-views"], limit: 3) {
      id
      title
      videoUrl: video_url
      views
      description
      article: article_id {
        id
        title
      }
    }
  }
`;

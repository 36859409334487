import {environment} from "src/environments/environment";

import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {registerLocaleData} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";

import ar from "@angular/common/locales/ar";

import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatPaginatorModule} from "@angular/material/paginator";

import {ApolloModule, APOLLO_OPTIONS} from "apollo-angular";
import {HttpLink} from "apollo-angular/http";
import {InMemoryCache} from "@apollo/client/core";

import {LightgalleryModule} from "lightgallery/angular";

import {InfiniteScrollModule} from "ngx-infinite-scroll";

import {AppRoutingModule} from "./app-routing.module";

import {FlickityDirective} from "./directives/flickity.directive";
import {SlickItemDirective} from "./directives/slick-item.directive";
import {CounterDirective} from "./directives/counter.directive";

import {ImageLinkPipe} from "./pipes/image-link.pipe";
import {FileLinkPipe} from "./pipes/file-link.pipe";
import {FileTypePipe} from "./pipes/file-type.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

import {AppComponent} from "./app.component";
import {CopyrightComponent} from "./components/shared/copyright/copyright.component";
import {FontsShareComponent} from "./components/shared/fonts-share/fonts-share.component";
import {SlickCarouselComponent} from "./components/shared/slick-carousel/slick-carousel.component";
import {LoaderComponent} from "./components/shared/loader/loader.component";
import {PageComponent} from "./components/shared/page/page.component";
import {PageContentComponent} from "./components/shared/page/page-content/page-content.component";
import {PageHeaderComponent} from "./components/shared/page/page-header/page-header.component";
import {PageFooterComponent} from "./components/shared/page/page-footer/page-footer.component";
import {NavbarComponent} from "./components/shared/navbar/navbar.component";
import {HeroComponent} from "./components/shared/hero/hero.component";
import {BreadcrumbComponent} from "./components/shared/breadcrumb/breadcrumb.component";
import {GalleryComponent} from "./components/shared/gallery/gallery.component";

import {YouTubePlayerModule} from "@angular/youtube-player";
import {LandingPageComponent} from "./components/pages/landing-page/landing-page.component";

import {LandingSliderComponent} from "./components/pages/landing-page/landing-slider/landing-slider.component";
import {SearchPopupComponent} from "./components/shared/search-popup/search-popup.component";
import {LandingOpeningComponent} from "./components/pages/landing-page/landing-opening/landing-opening.component";
import {FrontPageCardComponent} from "./components/shared/front-page-card/front-page-card.component";
import {FooterComponent} from "./components/shared/footer/footer.component";
import {FrontPageCardDarkComponent} from "./components/shared/front-page-card-dark/front-page-card-dark.component";
import {VideosComponent} from "./components/shared/videos/videos.component";
import {ArticlesPageComponent} from "./components/pages/articles-page/articles-page.component";
import {IssuesPageComponent} from "./components/pages/issues-page/issues-page.component";
import {ArticleDetailsPageComponent} from "./components/pages/article-details-page/article-details-page.component";
import {RegisterationPageComponent} from "./components/pages/registeration-page/registeration-page.component";
import {SearchComponent} from "./components/pages/search/search.component";
import { EducationalHighlightsDetailsPageComponent } from './components/pages/educational-highlights-details-page/educational-highlights-details-page.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ContactUsPageComponent } from './components/pages/contact-us-page/contact-us-page.component';

const angular = [BrowserModule, BrowserAnimationsModule, HttpClientModule, FormsModule, ReactiveFormsModule];

const material = [
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  MatPaginatorModule,
  YouTubePlayerModule,
];

const thirdParties = [ApolloModule, LightgalleryModule, InfiniteScrollModule, RecaptchaV3Module];

const directives = [FlickityDirective, SlickItemDirective, CounterDirective];

const pipes = [ImageLinkPipe, FileLinkPipe, FileTypePipe];

const components = [
  AppComponent,
  CopyrightComponent,
  FontsShareComponent,
  SlickCarouselComponent,
  LoaderComponent,
  PageComponent,
  PageContentComponent,
  PageHeaderComponent,
  PageFooterComponent,
  NavbarComponent,
  HeroComponent,
  BreadcrumbComponent,
  GalleryComponent,
  LandingPageComponent,
  LandingSliderComponent,
  SearchPopupComponent,
  LandingOpeningComponent,
  FrontPageCardComponent,
  FooterComponent,
  FrontPageCardDarkComponent,
  VideosComponent,
  ArticlesPageComponent,
];

registerLocaleData(ar);

@NgModule({
  declarations: [
    ...directives,
    ...pipes,
    ...components,
    IssuesPageComponent,
    ArticleDetailsPageComponent,
    RegisterationPageComponent,
    SearchComponent,
    EducationalHighlightsDetailsPageComponent,
    SafeUrlPipe,
    ContactUsPageComponent,
  ],
  imports: [...angular, ...material, ...thirdParties, FontAwesomeModule, AppRoutingModule],
  providers: [
    ...pipes,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlURI,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

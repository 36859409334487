<app-page>
  <page-header></page-header>
  <page-content>
    <div id="versions" class="mt-0 pb-5">
      <div style="background-color: #dfe4e7" class="py-5">
        <div class="container">
          <h4 class="fs-3 mb-4">في هذا العدد</h4>

          <div class="d-flex flex-column flex-md-row gap-4 justify-content-center">
            <div class="">
              <img [src]="issues[0].image | imageLink" class="mx-auto d-block version-image" [alt]="issues[0].image.title" />
            </div>
            <div class="d-flex flex-column justify-content-center">
              <h5 class="fw-bold">{{ issues[0].name }}</h5>
              <p class="small">{{ issues[0].date | date }}</p>
              <a [href]="issues[0].url" target="_blank" class="btn btn-light-gray rounded-0 mt-3">تصفح النسخة الرقمية</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h4 class="fs-3 mt-4 mb-4">في هذا العدد</h4>

        <div class="row">
          <div *ngFor="let issue of issues" class="col-xl-3 col-lg-4 col-md-6 mb-3">
            <a [href]="issue.url" target="_blank" class="text-dark">
              <div class="versions-card">
                <img
                  [src]="issue.image | imageLink"
                  class="mx-auto d-block version-image mb-3 hover-shadow"
                  [alt]="issue.image.title"
                />
                <h5 class="fw-bold">{{ issue.name }}</h5>
                <p class="small">{{ issue.date | date }}</p>
              </div>
            </a>
          </div>
        </div>

        <div *ngIf="totalIssuesCount && issues.length < totalIssuesCount" class="text-end cursor-pointer">
          <a (click)="getNextPage()" target="_self" class="text-dark fs-4">
            عرض المزيد
            <i class="fa-solid fa-plus text-gold ms-2"></i>
          </a>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

import {gql} from "apollo-angular";
import {EducationalHighlightsPage} from "../interfaces/educational-highlights-page";
import {EducationalHighlight} from "../interfaces/landing-page";

export const GET_SINGLE_EDUCATIONALHIGHLIGHTS_DETAILS = gql<
  {page: EducationalHighlightsPage; educationalHighlight: EducationalHighlight},
  {educationalHighlightId: string}
>`
  query ($educationalHighlightId: ID!) {
    page: teaching_aids_page {
      id
      title
      image: header_image {
        id
        filename: filename_download
        title
      }
    }
    educationalHighlight: educational_highlights_by_id(id: $educationalHighlightId) {
      id
      title
      bookTitle: book_title
      summary
      author
      date
      image {
        id
        filename: filename_download
        title
      }
      storeUrl: store_url
    }
  }
`;

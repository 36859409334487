import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LandingPageComponent} from "./components/pages/landing-page/landing-page.component";
import {LandingPageResolver} from "./resolvers/landing-page.resolver";
import {ArticlesPageComponent} from "./components/pages/articles-page/articles-page.component";
import {ArticlesPagesResolver} from "./resolvers/articles-pages.resolver";
import {IssuesPageComponent} from "./components/pages/issues-page/issues-page.component";
import {IssuesPageResolver} from "./resolvers/issues-page.resolver";
import {ArticleDetailsPageComponent} from "./components/pages/article-details-page/article-details-page.component";
import {ArticleDetailsPageResolver} from "./resolvers/article-details-page.resolver";
import {RegisterationPageComponent} from "./components/pages/registeration-page/registeration-page.component";
import {RegisterationPageResolver} from "./resolvers/registeration-page.resolver";
import {SearchComponent} from "./components/pages/search/search.component";
import {EducationalHighlightsDetailsPageResolver} from "./resolvers/educational-highlights-details-page.resolver";
import {EducationalHighlightsDetailsPageComponent} from "./components/pages/educational-highlights-details-page/educational-highlights-details-page.component";
import {ContactUsPageComponent} from "./components/pages/contact-us-page/contact-us-page.component";
import {ContactUsPageResolver} from "./resolvers/contact-us-page.resolver";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    resolve: {page: LandingPageResolver},
  },

  {
    path: "opening", // الافتتاحية
    component: ArticlesPageComponent,
    data: {pageType: 1, pageName: "opening"},
    resolve: {page: ArticlesPagesResolver},
  },

  {
    path: "opening/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 1, pageName: "opening"},
    resolve: {page: ArticleDetailsPageResolver},
  },

  {
    path: "e-learning-technology", // تكنولوجيا التعليم
    component: ArticlesPageComponent,
    data: {pageType: 2, pageName: "e-learning-technology"},
    resolve: {page: ArticlesPagesResolver},
  },
  {
    path: "e-learning-technology/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 2, pageName: "e-learning-technology"},
    resolve: {page: ArticleDetailsPageResolver},
  },

  {
    path: "documentaries", // وثائقيات
    component: ArticlesPageComponent,
    data: {pageType: 3, pageName: "documentaries"},
    resolve: {page: ArticlesPagesResolver},
  },

  {
    path: "documentaries/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 3, pageName: "documentaries"},
    resolve: {page: ArticleDetailsPageResolver},
  },

  {
    path: "teaching-methods", // طرق التدريس
    component: ArticlesPageComponent,
    data: {pageType: 4, pageName: "teaching-methods"},
    resolve: {page: ArticlesPagesResolver},
  },

  {
    path: "teaching-methods/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 4, pageName: "teaching-methods"},
    resolve: {page: ArticleDetailsPageResolver},
  },
  {
    path: "international-experiences", // تجارب دولية
    component: ArticlesPageComponent,
    data: {pageType: 5, pageName: "international-experiences"},
    resolve: {page: ArticlesPagesResolver},
  },

  {
    path: "international-experiences/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 5, pageName: "international-experiences"},
    resolve: {page: ArticleDetailsPageResolver},
  },
  {
    path: "childhood-studies", // دراسات الطفولة
    component: ArticlesPageComponent,
    data: {pageType: 6, pageName: "childhood-studies"},
    resolve: {page: ArticlesPagesResolver},
  },
  {
    path: "childhood-studies/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 6, pageName: "childhood-studies"},
    resolve: {page: ArticleDetailsPageResolver},
  },

  {
    path: "training", // التطوير والتدريب
    component: ArticlesPageComponent,
    data: {pageType: 7, pageName: "training"},
    resolve: {page: ArticlesPagesResolver},
  },

  {
    path: "training/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 7, pageName: "training"},
    resolve: {page: ArticleDetailsPageResolver},
  },
  {
    path: "special-education", // التربية الخاصة
    component: ArticlesPageComponent,
    data: {pageType: 8, pageName: "special-education"},
    resolve: {page: ArticlesPagesResolver},
  },

  {
    path: "special-education/:articleId",
    component: ArticleDetailsPageComponent,
    data: {pageType: 8, pageName: "special-education"},
    resolve: {page: ArticleDetailsPageResolver},
  },

  {
    path: "educational-highlights/:educationalHighlightId",
    component: EducationalHighlightsDetailsPageComponent,
    data: {pageType: 9, pageName: "educationalHighlight"},
    resolve: {page: EducationalHighlightsDetailsPageResolver},
  },

  {
    path: "issues",
    component: IssuesPageComponent,
    data: {pageType: 10, pageName: "issues"},
    resolve: {page: IssuesPageResolver},
  },

  {
    path: "register",
    component: RegisterationPageComponent,
    resolve: {page: RegisterationPageResolver},
  },
  {path: "contact-us", component: ContactUsPageComponent, resolve: {page: ContactUsPageResolver}},

  {path: "search", component: SearchComponent, data: {page: {title: "البحث"}}},
  {path: "**", redirectTo: ""},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

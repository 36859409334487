import {gql} from "apollo-angular";
import {TarbyaCategory} from "../interfaces/tarbya-categories";

export const GET_TARBYA_CATEGORIES = gql<{tarbyaCategories: TarbyaCategory[]}, void>`
  query {
    tarbyaCategories: categories(filter: {status: {_eq: "published"}}, sort: ["-order"]) {
      id
      nameAr: name_ar
      nameEn: name_en
      image {
        id
        title
        filename: filename_download
      }
    }
  }
`;

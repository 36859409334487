<div id="register" class="auth">
  <div class="row h-100">
    <div class="col-lg-6 order-1 order-lg-0 d-none d-lg-flex">
      <img src="assets/images/register-bg-img.png" class="cover w-100" style="height: 100vh" alt="" />
    </div>
    <div class="col-lg-6 order-0 order-lg-1">
      <div class="p-5 d-flex flex-column h-100">
        <div class="d-flex justify-content-between align-items-center">
          <img routerLink="/" class="cursor-pointer" src="assets/images/abegs-vertical-new.svg" height="82px" alt="Logo" />

          <a routerLink="/" class="btn btn-link text-secondary">
            <i class="fa-solid fa-arrow-left"></i>
          </a>
        </div>

        <div class="flex-grow-1 d-flex flex-column justify-content-center">
          <div>
            <div class="container">
              <h3 class="text-primary fw-bold mt-3">التسجيل في نشرة التربية</h3>
              <p class="text-l-gray fw-light">أنشئ حسابًا ليصلك أحدث المقالات.</p>
              <form [formGroup]="formGroup" class="mt-3">
                <div class="row">
                  <!-- name -->
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="firstName">الاسم الأول</label>
                      <input
                        formControlName="firstName"
                        required
                        type="text"
                        class="form-control py-3 focus-shadow-none"
                        id="firstName"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.firstNameErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="firstName?.touched && firstName?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="middleName">الاسم الأوسط</label>
                      <input
                        formControlName="middleName"
                        required
                        type="text"
                        class="form-control py-3 focus-shadow-none"
                        id="middleName"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.middleNameErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="middleName?.touched && middleName?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="lastName">الاسم الأخير</label>
                      <input
                        formControlName="lastName"
                        required
                        type="text"
                        class="form-control py-3 focus-shadow-none"
                        id="lastName"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.lastNameErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="lastName?.touched && lastName?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <!-- end name -->

                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="email">البريد الالكتروني</label>
                      <input
                        formControlName="email"
                        required
                        type="email"
                        class="form-control py-3 focus-shadow-none"
                        id="email"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.emailErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="email?.touched && email?.hasError(error.type)">{{ error.message }}</mat-error>
                      </ng-template>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="password">كلمة المرور</label>
                      <input
                        formControlName="password"
                        required
                        type="password"
                        class="form-control py-3 focus-shadow-none"
                        id="password"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.passwordErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="password?.touched && password?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="confirmPassword">تأكيد كلمة المرور</label>
                      <input
                        formControlName="confirmPassword"
                        required
                        type="password"
                        class="form-control py-3 focus-shadow-none"
                        id="confirmPassword"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.passwordErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="confirmPassword?.touched && confirmPassword?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="country">الدولة</label>
                      <select
                        formControlName="country"
                        aria-label="country"
                        class="form-control py-3 focus-shadow-none form-select form-select-lg"
                      >
                        <option selected value="" disabled>اختر الدولة</option>
                        <option *ngFor="let country of countries" [value]="country.id">
                          {{ country.nameAr }}
                        </option>
                      </select>
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.countryErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="country?.touched && country?.hasError(error.type)">{{ error.message }}</mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="employer">الجهة</label>
                      <input
                        formControlName="employer"
                        required
                        type="text"
                        class="form-control py-3 focus-shadow-none"
                        id="employer"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.employerErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="employer?.touched && employer?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="gender">النوع</label>
                      <select
                        formControlName="gender"
                        aria-label="Default select example"
                        class="form-control py-3 focus-shadow-none form-select form-select-lg"
                      >
                        <option selected value="" disabled>اختر النوع</option>
                        <option value="1">ذكر</option>
                        <option value="2">أنثي</option>
                      </select>
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.genderErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="gender?.touched && gender?.hasError(error.type)">{{ error.message }}</mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="selectedCategories">المجالات</label>
                      <select
                        multiple
                        formControlName="selectedCategories"
                        aria-label="selectedCategories"
                        class="form-control py-3 focus-shadow-none form-select form-select-lg"
                      >
                        <option selected value="" disabled>اختر المجالات</option>
                        <option *ngFor="let category of allCategories" [value]="category.id">
                          {{ category.nameAr }}
                        </option>
                      </select>
                      <ng-template
                        ngFor
                        let-error
                        [ngForOf]="ErrorMessages.selectedCategoriesErrors"
                        ngProjectAs="mat-error"
                      >
                        <mat-error *ngIf="selectedCategories?.touched && selectedCategories?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="fw-light text-l-gray mb-2" for="phone">رقم الجوال</label>
                      <input
                        formControlName="phone"
                        required
                        type="text"
                        class="form-control py-3 focus-shadow-none"
                        id="phone"
                      />
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.phoneErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="phone?.touched && phone?.hasError(error.type)">{{ error.message }}</mat-error>
                      </ng-template>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="mb-3">
                      <input
                        formControlName="mailingList"
                        class="me-3 focus-shadow-none form-check-input"
                        type="checkbox"
                        value=""
                        id="mailingList"
                        checked
                      />
                      <label class="fw-light text-l-gray mb-2" for="mailingList">
                        الموافقة علي إرسال المواد الإعلانية عبر البريد الالكتروني
                      </label>
                      <ng-template ngFor let-error [ngForOf]="ErrorMessages.mailingListErrors" ngProjectAs="mat-error">
                        <mat-error *ngIf="mailingList?.touched && mailingList?.hasError(error.type)">
                          {{ error.message }}
                        </mat-error>
                      </ng-template>
                    </div>
                  </div>

                  <div class="col-12">
                    <button
                      type="submit"
                      class="btn btn-primary w-100 py-3 mt-2"
                      (click)="onSubmit()"
                      [disabled]="sending || formGroup.invalid || success || hasError"
                      [ngClass]="{
                        'btn-primary': !hasError,
                        'btn-danger': hasError
                      }"
                    >
                      <span *ngIf="!success && !hasError">
                        <i *ngIf="sending" class="fa-solid fa-circle-notch fa-spin ms-1"></i>
                        تقديم
                      </span>
                      <span *ngIf="success" class="fade-in-down">
                        <i class="fa-solid fa-check"></i>
                        تم التسجيل في نشرة التربية بنجاح
                      </span>
                      <span *ngIf="hasError && errorMessage" class="fade-in-down">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                        {{ errorMessage }}
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from "@angular/core";
import { Article } from "src/app/interfaces/landing-page";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-front-page-card-dark",
  templateUrl: "./front-page-card-dark.component.html",
  styleUrls: ["./front-page-card-dark.component.scss"],
})
export class FrontPageCardDarkComponent implements OnInit {
  @Input() article!: Article;
  constructor() {}
  assetsURI = environment.assetsURI;

  ngOnInit(): void {}
}

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Video } from "src/app/interfaces/landing-page";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";
import { LightGallery } from "lightgallery/lightgallery";
import { AfterSlideDetail, InitDetail } from "lightgallery/lg-events";
import { ReplaySubject, takeUntil } from "rxjs";
import { ActiveButton } from "../../pages/landing-page/landing-page.component";
import { CMSService } from "src/app/services/cms.service";

@Component({
  selector: "app-videos",
  templateUrl: "./videos.component.html",
  styleUrls: ["./videos.component.scss"],
})
export class VideosComponent implements OnInit, OnDestroy, OnChanges {
  constructor(private cmsService: CMSService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["activeButton"].currentValue === ActiveButton.latestButton) {
      this.videos = this.latestVideos;
    } else {
      this.videos = this.mostViewedVideos;
    }
    this.triggerLightgalleryRefresh();
  }
  videos: Video[] = [];

  @Input() activeButton!: ActiveButton;
  unsubscribe$ = new ReplaySubject(1);

  mostViewsVideosActive: boolean = false;
  latestVideosActive: boolean = true;

  latestVideos!: Video[];
  mostViewedVideos!: Video[];

  loading!: boolean;
  lightGallery!: LightGallery;
  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
  };

  refresh!: boolean;

  settings = {
    licenseKey: "123",
    thumbnail: true,
    counter: false,
    selector: ".item",
    videojs: true,
    videojsOptions: {
      muted: true,
    },
    plugins: [lgVideo, lgThumbnail],
  };

  @Input() allVideos!: { latestVideos: Video[]; mostViewedVideos: Video[] };
  ngOnInit(): void {
    this.latestVideos = this.allVideos.latestVideos;
    this.mostViewedVideos = this.allVideos.mostViewedVideos;
    this.videos = this.latestVideos;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
  getThumbnailURL(url: string): string {
    const normalizedURL = new URL(url);
    if (
      ["www.youtube.com", "youtube.com", "youtu.be"].indexOf(
        normalizedURL.host
      ) !== -1
    ) {
      let videoId: string | undefined;
      const v = normalizedURL.searchParams.get("v");
      if (v && v.match(/^[\w-]{10,12}$/)) {
        videoId = v;
      } else {
        const match = /^(?:\/embed)?\/([\w-]{10,12})$/.exec(
          normalizedURL.pathname
        );
        if (match) {
          videoId = match[1];
        }
      }

      if (videoId) {
        return `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
    }
    return "http://img.youtube.com/vi/0/hqdefault.jpg";
  }
  openGallery(index: number, video: Video) {
    this.lightGallery.openGallery(index);
  }
  onAfterSlide = (detail: AfterSlideDetail): void => {
    const { index } = detail;
    let currentVideo!: Video;
    if (this.activeButton === ActiveButton.latestButton) {
      currentVideo = this.latestVideos.filter((video: Video, videoIndex: number) => videoIndex === index)[0]
    } else if (this.activeButton === ActiveButton.mostViewedButton) {
      currentVideo = this.mostViewedVideos.filter((video: Video, videoIndex: number) => videoIndex === index)[0]
    }

    let newViewsCount = currentVideo.views + 1;
    this.updateVideoViews(currentVideo.id, newViewsCount)
  };


  updateArticleViews(articleId: number | string, views: number) {
    this.cmsService.updateArticleViewsCount(articleId, views).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  updateVideoViews(videoId: number | string, views: number) {
    this.cmsService.updateVideoViewsCount(videoId, views).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  // FIXME This is a hack and there should be a better way to do this
  triggerLightgalleryRefresh() {
    setTimeout(() => {
      this.refresh = true;
    });
    setTimeout(() => {
      this.refresh = false;
    });
  }


}

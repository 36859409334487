import {Component, Input, OnInit} from "@angular/core";
import {Issue} from "src/app/interfaces/issues-page";
import {Article} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-opening",
  templateUrl: "./landing-opening.component.html",
  styleUrls: ["./landing-opening.component.scss"],
})
export class LandingOpeningComponent implements OnInit {
  @Input() opening!: Article[];
  @Input() issues!: Issue[];
  constructor() {}

  ngOnInit(): void {}
}

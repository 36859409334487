<app-page>
  <page-header></page-header>
  <page-content>
    <div id="read-more" class="py-5">
      <div class="container">
        <div class="text-center">
          <img
            *ngIf="article.images[0]"
            src="{{ assetsURI }}/{{ article.images[0].imageId.id }}"
            class="img-fluid rounded-3 shadow-lg w-75"
            style="max-height: 80vh !important"
            alt=""
          />
        </div>
        <!-- <h1 class="fs-4 mt-5 mb-1 fw-bold">عنوان المقال:</h1> -->

        <h3 class="mt-5 mb-3">{{ article.title }}</h3>
        <!-- <h1 class="fs-4 mt-3 mb-1 fw-bold">اسم الكاتب:</h1> -->
        <p class="m-0">{{ article.author }}</p>
        <!-- <h1 class="fs-4 mt-3 mb-1 fw-bold">ملخص الكتاب:</h1> -->
        <p class="m-0" *ngIf="article.summary" [innerHTML]="article.summary"></p>
        <!-- <h1 *ngIf="article.issue && article.issue.url" class="fs-4 mt-3 mb-1 fw-bold">رابط العدد:</h1> -->
        <a *ngIf="article.issue && article.issue.url" [href]="article.issue.url" target="_blank">{{ article.issue.url }}</a>

        <!-- <h1 class="fs-4 mt-3 mb-1 fw-bold">المحتوي:</h1> -->

        <p [innerHTML]="article.content" class="mt-5 mb-2"></p>
      </div>
    </div>

    <div *ngIf="article.videos[0] && article.videos[0].videoUrl" class="container mb-5">
      <div class="row">
        <iframe
          [title]="article.videos[0].videoUrl"
          width="560"
          height="450"
          [src]="videoUrl | safeUrl"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

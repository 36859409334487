import {ImageFile} from "./image-file";
import {Issue} from "./issues-page";
import {PageData} from "./page-data";
import {TarbyaCategory} from "./tarbya-categories";

export interface LandingPage {
  title: string;
  summary: string;
}
export interface Banner {
  id: string;
  title: string;
  image: ImageFile;
  url: string;
  order: number;
}

export interface Article {
  id: string;
  title: string;
  summary: string;
  author: string;
  authorImage: ImageFile;
  date: string;
  issue: Issue;
  content: string;
  images: {imageId: {id: string}}[];
  videos: Video;
  category_id: TarbyaCategory;
}

export interface Video {
  id: string;
  title: string;
  views: number;
  createAt: string;
  videoType: {id: string; nameAr: string; nameEn: string};
  videoUrl: string;
  description: string;
  article: {id: string; title: string};
  book: {id: string; title: string};
}

export interface EducationalHighlight {
  id: string;
  title: string; //عنوان الاضاءه
  bookTitle: string; // عنوان الكتاب
  summary: string;
  author: string;
  date: string;
  image: ImageFile;
  storeUrl: string;
}

export type LandingPageData = PageData<
  {
    banners: Banner[];
    latestArticles: Article[];
    mostViewedArticles: Article[];
    opening: Article[];
    issues: Issue[];
    teacingMethods: Article[];
    eLearningTechnology: Article[];
    childhoodStudies: Article[];
    developmentAndTeaching: Article[];
    videos: Video[];
  },
  LandingPage
>;

import {gql} from "apollo-angular";
import {Country} from "../interfaces/registeration-page";
import {TarbyaCategory} from "../interfaces/tarbya-categories";

export const GET_REGISTERATION_PAGE_DATA = gql<{countries: Country[]; allCategories: TarbyaCategory[]}, void>`
  query {
    countries(filter: {status: {_eq: "published"}}, sort: ["-date_created"]) {
      id
      nameAr: name_ar
      nameEn: name_en
      alpha2
      alpha3
    }

    allCategories: categories(filter: {status: {_eq: "published"}}, sort: ["-date_created"]) {
      id
      nameAr: name_ar
      nameEn: name_en
    }
  }
`;

import {gql} from "apollo-angular";
import {Issue, IssuesPage} from "../interfaces/issues-page";

export const GET_ISSUES = gql<{page: IssuesPage; issues: Issue[]; paging: {count: {id: number}}}, {limit: number}>`
  query ($limit: Int!) {
    page: issues_page {
      title
      image {
        id
        title
        filename: filename_download
      }
    }
    issues(filter: {status: {_eq: "published"}}, sort: ["-date_created"], limit: $limit) {
      id
      name
      date
      image {
        id
        title
        filename: filename_download
      }
      url
    }
    paging: issues_aggregated {
      count {
        id
      }
    }
  }
`;

export const GET_ISSUES_BY_PAGE = gql<{issues: Issue[]}, {page: number; limit: number}>`
  query ($limit: Int!, $page: Int!) {
    issues(filter: {status: {_eq: "published"}}, sort: ["-date_created"], page: $page, limit: $limit) {
      id
      name
      date
      image {
        id
        title
        filename: filename_download
      }
      url
    }
  }
`;

<nav id="navbar_top" class="navbar navbar-expand-xl py-1 py-xl-0" style="background: #eef2f5">
  <div class="container-fluid py-3 px-4 py-xl-0 px-xl-4">
    <button
      class="navbar-toggler me-3"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#mainOffcanvas"
      aria-label="sidebar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand py-0" routerLink="/" target="_self">
      <img class="d-block d-xl-none" src="assets/images/tarbeya-logo.svg" height="50px" alt="Logo" />
    </a>
    <!-- don't delete the following div please -->
    <div></div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="mainOffcanvas" aria-labelledby="mainOffcanvasLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="mainOffcanvasLabel"></h5>
        <button type="button" class="btn btn-link text-light fs-4 pt-3" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="bi bi-x-xl"></i>
        </button>
      </div>

      <div class="offcanvas-body flex-xl-column py-4" id="navbar-top">
        <div class="d-flex justify-content-between align-items-center px-3 px-xl-0">
          <div class="d-flex gap-3">
            <div routerLink="/register" id="">
              <button class="btn btn-link text-dark" title="التسجيل">
                <i class="fa-regular fa-user fs-4"></i>
              </button>
            </div>
            <div (click)="openSearchPopup()" id="search">
              <button id="search-button" title="البحث" class="btn btn-link text-dark">
                <i class="fa-solid fa-magnifying-glass fs-4"></i>
              </button>
            </div>
          </div>
          <a class="navbar-brand py-0" routerLink="/" target="_self">
            <img class="d-none d-xl-block" src="assets/images/tarbeya-logo.svg" height="82px" alt="Logo" />
          </a>
          <div class="d-flex gap-3 flex-row-reverse">
            <div routerLink="/contact-us" id="">
              <button class="btn btn-link text-dark" title="تواصل معنا">
                <i class="fas fa-envelope fs-4"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3 nav-area-mobile">
          <!-- navbar links -->
          <ul class="navbar-nav ms-auto mb-2 mb-xl-0 justify-content-center">
            <ng-container *ngFor="let link of navbar">
              <li class="nav-item mx-2 px-1 ml-3 nav-item-li">
                <a
                  target="_self"
                  class="fs-5 d-none d-xl-inline-flex nav-link"
                  [ngClass]="{active: link.href.includes(currentRoute)}"
                  [routerLink]="link.href"
                >
                  {{ link.title }}
                </a>
                <!-- Offcanvas -->
                <a
                  target="_self"
                  class="d-xl-none d-flex justify-content-between nav-link small"
                  [ngClass]="{active: link.href.includes(currentRoute)}"
                  [routerLink]="link.href"
                >
                  {{ link.title }}
                </a>
              </li>
              <li class="nav-item vertical-split"></li>
            </ng-container>
          </ul>
          <!--  navbar links -->
        </div>
      </div>
    </div>
  </div>
</nav>

<app-search-popup></app-search-popup>

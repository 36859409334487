import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { Apollo } from "apollo-angular";
import { GET_REGISTERATION_PAGE_DATA } from "../queries/registeration-page.query";
import { RegisterationPageData } from "../interfaces/registeration-page";

@Injectable({
  providedIn: "root",
})
export class RegisterationPageResolver
  implements Resolve<RegisterationPageData>
{
  constructor(private apollo: Apollo) {}

  resolve(): Observable<RegisterationPageData> {
    return this.apollo
      .query({
        query: GET_REGISTERATION_PAGE_DATA,
      })
      .pipe(
        map((result): RegisterationPageData => {
          const data = result.data;
          return {
            title: "صفحة التسجيل",
            data: data,
          };
        })
      );
  }
}

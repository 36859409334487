import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ArticlesDetailsPageData} from "src/app/interfaces/articles-details.pages";
import {Article} from "src/app/interfaces/landing-page";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-article-details-page",
  templateUrl: "./article-details-page.component.html",
  styleUrls: ["./article-details-page.component.scss"],
})
export class ArticleDetailsPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  pageType!: number;
  assetsURI = environment.assetsURI;
  youtubeVideoUrl!: string;
  article!: Article;

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as ArticlesDetailsPageData;
    this.article = page.data.article;
    if (this.article.videos[0]) this.youtubeVideoUrl = this.article.videos[0].videoUrl;
  }

  get videoUrl() {
    const videoIdMatch = this.youtubeVideoUrl.match(/(?:\/|%3D|v=)([\w-]{11})(?:[%#?&]|$)/);
    const videoId = (videoIdMatch && videoIdMatch[1]) || "";
    return `https://www.youtube.com/embed/${videoId}`;
  }
}

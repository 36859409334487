import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, map} from "rxjs";
import {Apollo} from "apollo-angular";
import {EducationalHighlightsDetailsPageData} from "../interfaces/educational-highlights-details-page";
import {GET_SINGLE_EDUCATIONALHIGHLIGHTS_DETAILS} from "../queries/educational-highlights-details.query";

@Injectable({
  providedIn: "root",
})
export class EducationalHighlightsDetailsPageResolver implements Resolve<EducationalHighlightsDetailsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EducationalHighlightsDetailsPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_EDUCATIONALHIGHLIGHTS_DETAILS,
        variables: {
          educationalHighlightId: route.params["educationalHighlightId"],
        },
      })
      .pipe(
        map((result): EducationalHighlightsDetailsPageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: meta.title,
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta: meta,
          };
        }),
      );
  }
}

<app-navbar></app-navbar>
<div id="bulletin-home">
  <app-landing-slider [banners]="banners"></app-landing-slider>
  <app-landing-opening [opening]="opening" [issues]="issues"></app-landing-opening>

  <div style="background-color: #dfe4e7" class="py-5">
    <div class="container">
      <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">الأحدث</h4>

      <div class="row">
        <div *ngFor="let article of latestArticles" class="col-lg-4 col-md-6 mb-3">
          <a routerLink="/{{ article.category_id.nameEn }}/{{ article.id }}" class="text-dark">
            <app-front-page-card [article]="article"></app-front-page-card>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-5">
    <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">الأكثر قراءة</h4>

    <div class="row">
      <div *ngFor="let article of mostViewedArticles" class="col-lg-4 col-md-6 mb-3">
        <a routerLink="/{{ article.category_id.nameEn }}/{{ article.id }}" target="_self" class="text-dark">
          <app-front-page-card [article]="article"></app-front-page-card>
        </a>
      </div>
    </div>
  </div>

  <!-- 1 -->
  <div *ngIf="teacingMethods.length > 0" style="background-color: #dfe4e7" class="py-5">
    <div class="container">
      <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">طرق التدريس</h4>

      <div class="row">
        <div *ngFor="let teacingMethod of teacingMethods" class="col-lg-4 col-md-6 mb-3">
          <a routerLink="/teaching-methods/{{ teacingMethod.id }}" target="_self" class="text-dark">
            <app-front-page-card [article]="teacingMethod"></app-front-page-card>
          </a>
        </div>
      </div>

      <div class="text-end mb-5 mt-3">
        <a routerLink="//teaching-methods" target="_self" class="more-btn text-dark fs-5">
          المزيد من الموضوعات
          <i class="fa-solid fa-plus text-gold ms-2"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- 1 -->
  <!-- 2 -->
  <div class="container py-5">
    <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">تكنولوجيا التعليم</h4>

    <div class="row">
      <div *ngFor="let eLearningTechnologyItem of eLearningTechnology" class="col-lg-4 col-md-6 mb-3">
        <a routerLink="/e-learning-technology/{{ eLearningTechnologyItem.id }}" class="text-dark">
          <app-front-page-card [article]="eLearningTechnologyItem"></app-front-page-card>
        </a>
      </div>
    </div>

    <div class="text-end mb-5 mt-3">
      <a routerLink="/e-learning-technology" target="_self" class="more-btn text-dark fs-5">
        المزيد من الموضوعات
        <i class="fa-solid fa-plus text-gold ms-2"></i>
      </a>
    </div>
  </div>

  <!-- 2 -->
  <!-- 3 -->

  <div style="background-color: #000" class="py-5">
    <div class="container">
      <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between text-light">دراسات الطفولة</h4>

      <div class="row">
        <div *ngFor="let childhoodStudy of childhoodStudies" class="col-lg-4 col-md-6 mb-3">
          <a routerLink="/childhood-studies/{{ childhoodStudy.id }}" target="_self" class="text-dark">
            <app-front-page-card-dark [article]="childhoodStudy"></app-front-page-card-dark>
          </a>
        </div>
      </div>

      <div class="text-end mb-5 mt-3">
        <a routerLink="/childhood-studies" target="_self" class="more-btn text-light fs-5">
          المزيد من الموضوعات
          <i class="fa-solid fa-plus text-gold ms-2"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- 3 -->

  <!-- 4 -->

  <div class="container py-5">
    <h4 class="fs-3 my-4 fw-bold d-flex align-items-center justify-content-between">التطوير والتدريب</h4>

    <div class="row">
      <div *ngFor="let developmentAndTeachingSingle of developmentAndTeaching" class="col-lg-4 col-md-6 mb-3">
        <a routerLink="/training/{{ developmentAndTeachingSingle.id }}" target="_self" class="text-dark">
          <app-front-page-card [article]="developmentAndTeachingSingle"></app-front-page-card>
        </a>
      </div>
    </div>

    <div class="text-end mb-5 mt-3">
      <a routerLink="/training" target="_self" class="more-btn text-dark fs-5">
        المزيد من الموضوعات
        <i class="fa-solid fa-plus text-gold ms-2"></i>
      </a>
    </div>
  </div>

  <!-- 4 -->
</div>

<app-footer></app-footer>
<app-copyright></app-copyright>

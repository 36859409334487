import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, map} from "rxjs";
import {Apollo} from "apollo-angular";
import {GET_SINGLE_ARTICLE} from "../queries/article-details-page.query";
import {ArticlesDetailsPageData} from "../interfaces/articles-details.pages";

@Injectable({
  providedIn: "root",
})
export class ArticleDetailsPageResolver implements Resolve<ArticlesDetailsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticlesDetailsPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_ARTICLE,
        variables: {
          articleId: route.params["articleId"],
          pageType: route.data["pageType"],
        },
      })
      .pipe(
        map((result): ArticlesDetailsPageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: meta[0].nameAr,
            hero: {
              title: meta[0].nameAr,
              image: meta[0].image,
            },
            data: page,
            meta: meta[0],
          };
        }),
      );
  }
}

<div id="videos-gallery" style="background-color: #000" class="">
  <div class="container">
    <div *ngIf="!!videos && !refresh" class="tab-content" id="videos-tabsContent">
      <!-- video card -->

      <div *ngIf="videos[0]" class="videos-card h-100" (click)="openGallery(0, videos[0])">
        <a>
          <img class="w-100 h-100 cover rounded-3" [src]="getThumbnailURL(videos[0].videoUrl)" [alt]="videos[0].title" />
        </a>
      </div>

      <!-- end of video card -->
      <div *ngIf="videos[0]" class="tab-pane fade show active">
        <div class="mb-4">
          <h4 class="fw-bold mt-4 mb-2 text-light">
            {{ videos[0].title }}
          </h4>
          <p [innerHTML]="videos[0].description" class="mb-5 text-light"></p>
        </div>
      </div>
      <lightgallery *ngIf="videos[0]" [settings]="settings" [onInit]="onInit" [onAfterSlide]="onAfterSlide">
        <div class="row">
          <div
            (click)="openGallery(videoIndex, video)"
            *ngFor="let video of videos; index as videoIndex"
            class="col-lg-4 col-md-6 mb-3"
          >
            <div style="height: 250px">
              <!-- video card -->
              <div class="videos-card h-100">
                <a class="item" data-lg-size="1280-720" [attr.data-src]="video.videoUrl" [attr.data-sub-html]="video.title">
                  <img class="w-100 h-100 cover rounded-3" [src]="getThumbnailURL(video.videoUrl)" [alt]="video.title" />
                </a>
              </div>
              <!-- end of video card -->
              <h5 class="fw-bold mt-3 mb-2 text-light">
                {{ video.title }}
              </h5>
            </div>
          </div>
        </div>
      </lightgallery>
    </div>
  </div>
</div>

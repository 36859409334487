import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {ContactUsPage, ContactUsPageData, Inquiry} from "src/app/interfaces/contact-us-page";
import {ApiService} from "src/app/services/api.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-contact-us-page",
  templateUrl: "./contact-us-page.component.html",
  styleUrls: ["./contact-us-page.component.scss"],
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
  formGroup = new FormGroup({
    name: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl("", {nonNullable: true, validators: [Validators.required, Validators.email]}),
    mobile: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
    location: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
    inquiry: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
  });

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;

  page: ContactUsPage | undefined;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as ContactUsPageData;
    this.page = meta;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get name() {
    return this.formGroup.controls["name"];
  }

  get email() {
    return this.formGroup.controls["email"];
  }

  get mobile() {
    return this.formGroup.controls["mobile"];
  }

  get location() {
    return this.formGroup.controls["location"];
  }

  get inquiry() {
    return this.formGroup.controls["inquiry"];
  }

  onSubmit() {
    if (this.formGroup.invalid) return;
    this.sending = true;
    this.recaptchaV3Service
      .execute(environment.recaptcha.contactUsAction)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token) => {
          return this.apiService.submitAction(environment.recaptcha.contactUsAction, token, {
            ...this.formGroup.value,
          } as Inquiry);
        }),
      )
      .subscribe({
        next: () => {
          this.sending = false;
          this.success = true;
        },
        error: () => {
          this.sending = this.success = false;
          this.hasError = true;
        },
      });
  }
}

import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {ContactUsPageData} from "../interfaces/contact-us-page";
import {GET_CONTACT_US_PAGE} from "../queries/contact-us-page.query";

@Injectable({
  providedIn: "root",
})
export class ContactUsPageResolver implements Resolve<ContactUsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<ContactUsPageData> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE,
      })
      .pipe(
        map((result): ContactUsPageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: meta.title,
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [
              {title: "الصفحة الرئيسية", routerLink: ""},
              {title: meta.title, routerLink: "contact-us"},
            ],
            data: page,
            meta,
          };
        }),
      );
  }
}

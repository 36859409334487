// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphqlURI: "https://test-bulletin.abegs.org/cms/graphql",
  assetsURI: "https://test-bulletin.abegs.org/cms/assets",
  portalURI: "https://testserver.abegs.org",
  restApi: "https://test-bulletin.abegs.org/cms",
  cmsAdminURL: "https://test-bulletin.abegs.org/cms/admin",
  fileAPI: "https://test-bulletin.abegs.org/cms/files",
  serverURI: "https://test-bulletin.abegs.org/tarbiyah/api",
  limit: {
    issuesLimit: 4,
    articlesLimit: 3,
    booksLimit: 4,
    infographsLimit: 3,
    educationalHighlightsLimit: 3,
    educationalStudiesLimit: 3,
  },

  recaptcha: {
    siteKey: "6LcALQclAAAAAKMQAcyB5z0-e_4VLznW8MsWTTLU",
    registerUserAction: "bulletin_register_user",
    newslettersAction: "portal_newsletters",
    contactUsAction: "bulletin_contact_us",
  },
  analytics: {
    measurementId: "G-D5LVEGCZTJ",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ArticlesPageData} from "src/app/interfaces/articles.pages";
import {Article, Video} from "src/app/interfaces/landing-page";
import {environment} from "src/environments/environment";
import {ActiveButton} from "../landing-page/landing-page.component";
import {CMSService} from "src/app/services/cms.service";
import {ReplaySubject, takeUntil} from "rxjs";

@Component({
  selector: "app-articles-page",
  templateUrl: "./articles-page.component.html",
  styleUrls: ["./articles-page.component.scss"],
})
export class ArticlesPageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}

  latestArticles: Article[] = [];
  mostViewedArticles!: Article[];
  assetsURI = environment.assetsURI;
  pageType!: number;
  pageName!: string;
  page: number = 1;

  totalArticlesCount!: number;

  articleRoute!: string;

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as ArticlesPageData;
    this.latestArticles.push(...page.data.latestArticles);
    this.mostViewedArticles = page.data.mostViewedArticles;
    this.pageType = this.route.snapshot.data["pageType"];
    this.pageName = this.route.snapshot.data["pageName"];

    this.totalArticlesCount = page.data.paging[0].count.id;
  }
  activeButton = ActiveButton.latestButton;

  ActiveButton = ActiveButton;

  unsubscribe$ = new ReplaySubject(1);
  loading!: boolean;

  getNextPage() {
    this.page++;
    this.loading = true;
    this.cmsService
      .getArticlesByPage(this.page, this.pageType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: Article[]) => {
          this.latestArticles.push(...result);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { Banner } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-landing-slider",
  templateUrl: "./landing-slider.component.html",
  styleUrls: ["./landing-slider.component.scss"],
})
export class LandingSliderComponent implements OnInit {
  @Input() banners!: Banner[];
  constructor(private localizationService: LocalizationService) {}
  unsubscribe$ = new ReplaySubject(1);
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    pageDots: true,
    autoPlay: true,
    prevNextButtons: true,
  };
  ngOnInit(): void {    
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}

import {gql} from "apollo-angular";
import {ArticlesPage} from "../interfaces/articles.pages";
import {Article, Video} from "../interfaces/landing-page";

export const GET_ARTICLES = gql<
  {
    page: ArticlesPage;
    articles: Article[];
    latestArticles: Article[];
    mostViewedArticles: Article[];
    paging: {count: {id: number}};
  },
  {pageType: number}
>`
  query ($pageType: GraphQLStringOrFloat!) {
    page: categories(filter: {status: {_eq: "published"}, id: {_eq: $pageType}}, sort: ["-date_created"]) {
      id
      nameAr: name_ar
      nameEn: name_en
      order
      image: header_image {
        id
        filename: filename_download
        title
      }
    }

    articles(filter: {status: {_eq: "published"}, category_id: {id: {_eq: $pageType}}}, sort: ["-issue_id.date"]) {
      id
      date
      title
      summary
      author
      issue:issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
        views
        createAt: date_created
        videoUrl: video_url
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
    }
    latestArticles: articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: $pageType}}}
      sort: ["-issue_id.date"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue:issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
        views
        createAt: date_created
        videoUrl: video_url
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
    }

    mostViewedArticles: articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: $pageType}}}
      sort: ["-views"]
      limit: 3
    ) {
      id
      date
      title
      summary
      author
      issue:issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
        views
        createAt: date_created
        videoUrl: video_url
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
    }

    paging: articles_aggregated(filter: {status: {_eq: "published"}, category_id: {id: {_eq: $pageType}}}) {
      count {
        id
      }
    }
  }
`;

export const GET_ARTICLES_BY_PAGE = gql<{articles: Article[]}, {page: number; limit: number; pageType: number | string}>`
  query ($limit: Int!, $page: Int!, $pageType: GraphQLStringOrFloat!) {
    articles(
      filter: {status: {_eq: "published"}, category_id: {id: {_eq: $pageType}}}
      sort: ["-issue_id.date"]
      page: $page
      limit: $limit
    ) {
      id
      date
      title
      summary
      author
      issue:issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
        views
        createAt: date_created
        videoUrl: video_url
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
    }
  }
`;

export const UPDATE_VIDEOS_VIEWS_COUNT_BY_ID = gql<{video: Video}, {videoId: string | number; views: number}>`
  mutation ($videoId: ID!, $views: Int!) {
    update_videos_item(id: $videoId, data: {views: $views}) {
      id
      views
    }
  }
`;

export const UPDATE_ARTICLES_VIEWS_COUNT_BY_ID = gql<{article: Article}, {articleId: string | number; views: number}>`
  mutation ($articleId: ID!, $views: Int!) {
    update_articles_item(id: $articleId, data: {views: $views}) {
      id
      views
    }
  }
`;

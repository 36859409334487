<div *ngIf="article" class="frontpage-card">
  <img
    *ngIf="article.images[0]"
    src="{{ assetsURI }}/{{ article.images[0].imageId.id }}"
    class="mx-auto d-block frontpage-card-image mb-3 hover-shadow"
    alt=""
  />

  <h5 class="fw-bold p-make-2--fixed-lines-ended-with-dots" [innerHTML]="article.title"></h5>
  <!-- <p [innerHTML]="article.summary" class="text-muted my-3 p-make-2--fixed-lines-ended-with-dots"></p> -->

  <a class="bg-dark fw-bold text-dark bg-opacity-10 p-3 d-block">
    <i class="fa-solid fa-align-justify me-2"></i>
    اقرأ
  </a>
</div>

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

import AOS from "aos";
import {Article, Banner, LandingPageData, Video} from "src/app/interfaces/landing-page";
import {ReplaySubject, takeUntil} from "rxjs";
import {CMSService} from "src/app/services/cms.service";
import {Issue} from "src/app/interfaces/issues-page";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}
  banners!: Banner[];
  opening!: Article[];
  latestArticles!: Article[];
  mostViewedArticles!: Article[];
  issues!: Issue[];
  teacingMethods!: Article[];
  eLearningTechnology!: Article[];
  childhoodStudies!: Article[];
  developmentAndTeaching!: Article[];
  page = 0;
  unsubscribe$ = new ReplaySubject(1);
  loading: boolean = false;

  activeButton = ActiveButton.latestButton;

  ActiveButton = ActiveButton;

  ngOnInit() {
    const {data} = this.route.snapshot.data["page"] as LandingPageData;

    this.banners = data.banners;
    this.teacingMethods = data.teacingMethods;
    this.eLearningTechnology = data.eLearningTechnology;
    this.childhoodStudies = data.childhoodStudies;
    this.developmentAndTeaching = data.developmentAndTeaching;
    this.opening = data.opening;
    this.issues = data.issues;
    this.latestArticles = data.latestArticles;
    this.mostViewedArticles = data.mostViewedArticles;

    AOS.init({
      // disable: "mobile"
      once: true,
    });
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}

export enum ActiveButton {
  latestButton = "latest",
  mostViewedButton = "most-viewed",
}

<nav class="navbar navbar-expand-lg py-1 py-md-0" style="background: #eef2f5">
  <div class="container-fluid p-4 py-md-0 px-md-4 px-md-6 justify-content-center">
    <div class="d-flex flex-column py-5 gap-4">
      <div class="d-flex flex-column justify-content-between align-items-center">
        <a class="navbar-brand py-0" routerLink="/" target="_self">
          <img class="d-none d-lg-block" src="assets/images/tarbeya-logo.png" height="82px" alt="Logo" />
        </a>
      </div>
      <div></div>

      <div class="d-flex social-media-links justify-content-center">
        <a *ngFor="let link of socialMediaLinks" class="me-3" target="_blank" [href]="link.href">
          <fa-icon [icon]="link.icon"></fa-icon>
        </a>
      </div>

      <div>
        <!-- navbar links -->
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 justify-content-between">
          <ng-container *ngFor="let item of navbar">
            <li class="nav-item mx-1 px-1">
              <a
                target="_self"
                class="fs-5 d-none d-lg-inline-flex nav-link"
                [ngClass]="{active: item.active}"
                [routerLink]="item.href"
              >
                {{ item.title }}
              </a>

              <!-- Offcanvas -->
              <a
                target="_self"
                class="d-lg-none d-flex justify-content-between nav-link small"
                [ngClass]="{active: item.active}"
                [routerLink]="item.href"
              >
                {{ item.title }}
              </a>
            </li>
            <li class="nav-item vertical-split"></li>
          </ng-container>
        </ul>

        <div class="text-center mt-3">
          <p class="text-center fs-6 fw-bold">
            اشترك في النشرة البريدية ليصلك كل جديد، من
            <span><a routerLink="/register" class="text-center fw-bold">هنا</a></span>
          </p>
        </div>
        <!--  navbar links -->
        <!-- <app-newsletter></app-newsletter> -->
      </div>
    </div>
  </div>
</nav>
<button #scrollTopBtn routerLink="." class="btn btn-primary rounded-circle scroll-top-btn shadow">
  <i class="fa-solid fa-chevron-up"></i>
</button>

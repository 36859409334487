import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, map} from "rxjs";
import {Apollo} from "apollo-angular";
import {IssuesPageData} from "../interfaces/issues-page";
import {GET_ISSUES} from "../queries/issues-page.query";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IssuesPageResolver implements Resolve<IssuesPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IssuesPageData> {
    return this.apollo
      .query({
        query: GET_ISSUES,
        variables: {
          limit: environment.limit.issuesLimit,
        },
      })
      .pipe(
        map((result): IssuesPageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: meta.title,
            hero: {
              title: meta.title,
              image: meta.image,
            },
            breadcrumbs: [{title: meta.title}],
            data: page,
            meta,
          };
        }),
      );
  }
}

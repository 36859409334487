import {gql} from "apollo-angular";
import {Article} from "../interfaces/landing-page";
import {ArticlesDetailsPage} from "../interfaces/articles-details.pages";

export const GET_SINGLE_ARTICLE = gql<
  {page: ArticlesDetailsPage; article: Article},
  {articleId: string; pageType: number | string}
>`
  query ($articleId: ID!, $pageType: GraphQLStringOrFloat!) {
    page: categories(filter: {status: {_eq: "published"}, id: {_eq: $pageType}}, sort: ["-date_created"]) {
      id
      nameAr: name_ar
      nameEn: name_en
      order
      image: header_image {
        id
        filename: filename_download
        title
      }
    }
    article: articles_by_id(id: $articleId) {
      id
      date
      title
      summary
      author
      issue:issue_id {
        id
        name
        date
        url
      }
      content
      videos {
        id
        title
        description
        videoUrl: video_url
      }
      images {
        imageId: directus_files_id {
          id
        }
      }
      category_id {
        id
        nameAr: name_ar
        nameEn: name_en
        order
        image: header_image {
          id
          filename: filename_download
          title
        }
      }
    }
  }
`;

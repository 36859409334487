<app-page>
  <page-header></page-header>
  <page-content>
    <div id="contact-us">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-9 mx-auto">
            <div class="shadow card rounded-0">
              <div class="card-body p-5">
                <h5 class="text-primary fw-bold">يسعدنا التواصل معكم</h5>

                <hr style="border-style: dotted" />

                <form [formGroup]="formGroup">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name" class="form-label small">الاسم</label>
                        <input
                          required
                          type="text"
                          class="form-control rounded-0 focus-shadow-none"
                          [ngClass]="{'is-invalid': name.invalid && (name.dirty || name.touched)}"
                          id="name"
                          formControlName="name"
                        />
                        <div *ngIf="name.hasError('required')" class="invalid-feedback">الاسم مطلوب</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="email" class="form-label small">البريد الإلكتروني</label>
                        <input
                          required
                          type="email"
                          class="form-control rounded-0 focus-shadow-none"
                          [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}"
                          id="email"
                          formControlName="email"
                        />
                        <div *ngIf="email.hasError('required')" class="invalid-feedback">البريد الإلكتروني مطلوب</div>
                        <div *ngIf="email.hasError('email')" class="invalid-feedback">البريد الإلكتروني غير صحيح</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="tel" class="form-label small">رقم الجوال</label>
                        <input
                          required
                          type="tel"
                          class="form-control rounded-0 focus-shadow-none"
                          [ngClass]="{'is-invalid': mobile.invalid && (mobile.dirty || mobile.touched)}"
                          id="tel"
                          formControlName="mobile"
                        />
                        <div *ngIf="name.hasError('required')" class="invalid-feedback">رقم الجوال مطلوب</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="location" class="form-label small">العنوان</label>
                        <input
                          required
                          type="text"
                          class="form-control rounded-0 focus-shadow-none"
                          [ngClass]="{'is-invalid': location.invalid && (location.dirty || location.touched)}"
                          id="location"
                          formControlName="location"
                        />
                        <div *ngIf="name.hasError('required')" class="invalid-feedback">العنوان مطلوب</div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="inquiry" class="form-label small">الرسالة</label>
                        <textarea
                          required
                          rows="6"
                          class="form-control rounded-0 focus-shadow-none"
                          [ngClass]="{'is-invalid': inquiry.invalid && (inquiry.dirty || inquiry.touched)}"
                          id="inquiry"
                          formControlName="inquiry"
                        ></textarea>
                        <div *ngIf="name.hasError('required')" class="invalid-feedback">الرسالة مطلوبة</div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <button
                        class="btn rounded-0 py-3 w-100"
                        (click)="onSubmit()"
                        [disabled]="sending || formGroup.invalid || success || hasError"
                        [ngClass]="{'btn-primary': !hasError, 'btn-danger': hasError}"
                      >
                        <span *ngIf="!success && !hasError">
                          <i *ngIf="sending" class="fa-solid fa-circle-notch fa-spin ms-1"></i>
                          إرسال
                        </span>
                        <span *ngIf="success" class="fade-in-down">
                          <i class="fa-solid fa-check"></i>
                          تم إرسال الرسالة بنجاح
                        </span>
                        <span *ngIf="hasError" class="fade-in-down">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                          حدث خطأ يرجي المحاولة لاحقًا
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="shadow mt-5 card rounded-0">
              <div class="card-image">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.4406924822692!2d46.62238387876512!3d24.677373154954676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1c5aeb3823af%3A0xccf806fdbe26923a!2sABEGS!5e0!3m2!1sen!2seg!4v1672107424332!5m2!1sen!2seg"
                  width="100%"
                  height="300"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>
              </div>
              <div *ngIf="page" class="card-body p-5">
                <div class="mt-4 fs-5">
                  <p>
                    <i class="fa-solid fa-location-dot me-3"></i>
                    {{ page.location }}
                  </p>

                  <p>
                    <i class="fa-solid fa-phone fa-flip-horizontal me-3"></i>
                    <a class="" href="tel: 00966114800555">{{ page.phone }}</a>
                  </p>

                  <p>
                    <i class="fa-solid fa-envelope fa-flip-horizontal me-3"></i>
                    <a class="" href="mailto: abegs@abegs.org">{{ page.email }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
